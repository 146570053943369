import React from "react"
import Layout from "../components/layout"
import {graphql, Link} from "gatsby"
import {getAllContentfulNode} from "../common/utils/QueryUtil"
import WaveSvg from "../assets/images/Wave.svg"
import {isValidContentfulImage, isValidSectionField} from "../common/utils/CommonUtils"
import SEO from "../components/seo"
import BreadCrumbs from "../components/bread-crumbs";
import "./all-landing-ui.scss"

const breadCrumbsList  = [

    {
        title: "Home",
        link: "/",
    },
    {
        title: "Get Started",
        link: "/get-started/",
    },
    {
        title: "Support Groups",
        link: "/support-groups/",
    }

]

const navigateToGroupDetail = (slug) => {
    window.location.assign(`/support-groups/online-support-groups/${slug}/`);
};

export default (props, pageContext) => {

    const nodes = getAllContentfulNode(props, "allContentfulGroupDetailPage");

    return (
        <Layout>
            <SEO title="Our Groups"/>
            <BreadCrumbs currentPage={"All Groups"}  breadCrumbsList={breadCrumbsList}/>
            <div className="all-common-main">
                <div className="common-wrap-bg"
                     style={{backgroundImage: `url(${WaveSvg})`}}>
                    <h2 className="all-common-main-heading">Free Support
                        Groups</h2>
                    <p className="all-common-main-para">Confidant Virtual Groups
                        are a confidential place to learn and
                        find
                        support. Groups are led by trained facilitators and
                        clinical experts.</p>
                    <div className="common-wrap">
                        <div className="common-list">
                            {
                                nodes.map((item) => {
                                    return (
                                        <div className="single-card">
                                            {
                                                isValidContentfulImage(
                                                    item.groupImage) && (
                                                    <img className="card-img"
                                                         src={item.groupImage.file.url}
                                                         onClick={() => navigateToGroupDetail(
                                                             item.slug)}
                                                         alt="Card Visual"/>
                                                )

                                            }
                                            <div
                                                className="card-content-section">
                                                {
                                                    isValidSectionField(item,
                                                        "groupName") &&
                                                    <h3 className="card-title"
                                                        onClick={() => navigateToGroupDetail(
                                                            item.slug)}>{item.groupName}</h3>
                                                }
                                                <div className="card-info-wrap">
                                                    {
                                                        isValidSectionField(
                                                            item, "memberCount")
                                                        &&
                                                        <p className="card-info">{item.memberCount} Members</p>
                                                    }
                                                </div>
                                                {
                                                    isValidSectionField(item,
                                                        "groupDescription") &&
                                                    <p className="card-desc">{item.groupDescription}</p>
                                                }
                                                <Link className="outline-btn"
                                                      to={`/support-groups/online-support-groups/${item.slug}/`}
                                                      state={{groupname: item.groupName}}
                                                >Join</Link>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="after-common-wrap">
                <div className="after-common-inner">
                    <h4 className="after-common-head">We’ve been in your shoes,
                        so you don’t have to walk alone.</h4>
                    <p className="after-common-para">No matter what you’re going
                        through, we can relate.
                        You might need unbias information, a community of
                        support, or a sounding board.
                        Confidant offers groups all of kinds to meet the varying
                        needs of our community.
                        This includes groups focused on all ranges of the drug
                        use and addiction spectrum, focused on different mental
                        health conditions, different family roles, and more.
                        Some groups are for those working to maintain abstinence
                        while others support harm reduction, both are
                        facilitated by certified professionals.
                        <br/>
                        <br/>
                        Some groups are more “drop-in” hours while others are
                        one-time trainings, and some become a community of
                        support.
                        Groups are always changing, but if you don’t see a group
                        that fits what you’re looking for let us know, we can
                        start one!.</p>
                </div>
            </div>
        </Layout>
    )
}

export const query = graphql`
query allPublicGroupsQuery{
   allContentfulGroupDetailPage {
    nodes {
      groupName
      memberCount
      groupDescription
      slug
      groupImage {
        file {
          contentType
          url
        }
      }
    }
  }
}`;
